import React from "react";
import { graphql, Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/SEO";

const Project = ({ props }) => {
  return (
    <Link to={props.slug}>
      <GatsbyImage
        image={props.projectImageURL.childImageSharp.gatsbyImageData}
        alt={props.projectImageAlt}
      />
    </Link>
  );
};

const ProjectList = ({ props }) => {
  const projects = props;
  return (
    <ul class="columns">
      {projects.map((item, index) => (
        <li key={index} class="column">
          <Project props={item.node.frontmatter} />
        </li>
      ))}
    </ul>
  );
};

const ProjectsPage = (props) => {
  const projectList = props.data.projects.edges;
  return (
    <>
      <SEO title="projects" />
      <section className="section projects">
        <div className="container">
            <ProjectList props={projectList} />
          </div>
      </section>
    </>
  );
};

export default ProjectsPage;

export const projectPageQuery = graphql`
  query {
    projects: allMarkdownRemark(
      filter: {frontmatter: {published: {eq: true}}, fileAbsolutePath: {regex: "/project-pages/"}}
    ) {
      edges {
        node {
          fileAbsolutePath
          frontmatter {
            date
            slug
            title
            published
            projectImageAlt
            projectImageURL {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  width: 250
                )
              }
            }
          }
        }
      }
    }
  }
`;
